@import 'src/scss/variables';
@import 'src/scss/mixins/grid';
@import './others';
@import './yahoo';

.LightProductAd_lazy-load-wrapper {
	@include grid(5, 4);
	.LightProductAd_product-promo {
		&.LightProductAd_yahoo {
			@include yahooProductAd;
		}
		&.LightProductAd_others {
			@include othersProductAd;
		}
	}
}
