@import 'src/scss/variables';

$textAdColor: #2142cf;
$gTextAdColor: #1a0dab;

.TXT_text-promo {
  display: flex;
  padding-bottom: 10px;
  margin-bottom: 10px;
  text-align: left;
  font-size: 14px;
  position: relative;
  width: 100%;

  .TXT_wrapper {
    width: 100%;
  }

  &.TXT_action-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:last-child {
    border: none;
  }

  @media (max-width: $mobile) {
    box-shadow: 5px 5px 10px rgba(214, 214, 215, 0.25), -5px -5px 10px rgba(255, 255, 255, 0.25);
    background-color: white;
    border-radius: 4px;
    padding: 0px 5px 10px 5px;
    overflow: hidden;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .TXT_title {
    font-size: 1.2em;
    line-height: 1.3;
    color: $textAdColor;
    cursor: pointer;
  }

  .TXT_imgWrapper {
    text-align: center;
    display: block;

    .TXT_imgInAds {
      max-width: 48px;
      object-fit: contain;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-origin: content-box;
    }
  }

  .TXT_site-host {
    display: flex;
    margin-top: 2px;
    margin-bottom: 5px;
    line-height: 25px;
    align-items: center;
    cursor: pointer;
    word-break: break-all;
    display: inline;

    img {
      margin-right: 7px;
      width: 16px;
      position: relative;
      top: 4px;
    }

    .TXT_host {
      line-height: 15px;
      opacity: 0.9;
      color: rgba(13, 92, 39, 0.9);
    }

    .TXT_ad-label {
      min-width: 21px;
      text-align: center;
      font-size: 12px;
      line-height: 13px;
      display: inline-block;
      border-radius: 3px;
      padding: 0 2px 0px 1px;
      // color: #4272db;
      // border: 1px solid #4272db;
      color: rgba(51, 51, 51, 0.8);
      border: 1px solid rgba(51, 51, 51, 0.8);
      margin-right: 5px;
    }
  }

  .TXT_ratings {
    display: flex;
    align-items: center;
    padding: 3px 0px;

    >a {
      color: $textAdColor;
    }

    // > span {
    // padding-left: 5px;
    // }
  }

  .TXT_topLineWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 5px 0px;

    &.TXT_variant4Test {
      .TXT_titleSiteHost {
        height: 100%;
        // display: flex;
        // flex-direction: column;
      }

      .TXT_officialSiteAndImgWrapper {
        @media (min-width: $mobile) {
          flex-direction: row-reverse;

          .TXT_imgWrapper {
            margin-left: 10px !important;
          }

          .TXT_officialSiteBadge {
            margin-right: 0px !important;
            margin-top: 0px !important;
          }
        }

        .TXT_officialSiteBadge {
          margin-top: 5px;
          margin-right: 0px;
          margin-bottom: 0px;
        }
      }
    }
  }

  .TXT_descriptionLineWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row-reverse;

    &.TXT_variant4TestDescription {
      .TXT_descriptionWrapper {
        text-align: justify;
      }
    }

    .TXT_action-item~.TXT_descriptionWrapper {
      width: calc(100% - 85px) !important;
    }

    .TXT_descriptionWrapper {
      width: 100%;
    }
  }

  .TXT_description {
    line-height: 1.43;
    color: rgba(51, 51, 51, 0.8);
  }

  .TXT_action-item {
    border: 1px solid $textAdColor;
    color: $textAdColor;
    font-size: 14px;
    font-weight: bold;
    width: 80px;
    height: 32px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    &:hover {
      background-color: #f7f7fa;
      text-decoration: none;
    }
  }

  .TXT_text-promo-inner-with-cta {
    width: 85%; // ie fallback etc
    width: calc(100% - 100px);

    @media (max-width: $mobile) {
      width: 100%;
    }
  }

  .TXT_officialSiteText {
    font-weight: bold;
    color: #424242;
  }

  .TXT_officialSiteAndImgWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .TXT_officialSiteBadge {
      margin-right: 10px;
    }

    @media (max-width: $mobile) {
      flex-direction: column;
      width: 92px;

      .TXT_officialSiteBadge {
        margin-bottom: 5px;
      }
    }
  }

  .TXT_officialSiteBadge {
    background: #e2f2ff;
    border: 1px solid #cee9ff;
    box-sizing: border-box;
    line-height: 27px;
    border-radius: 6px;
    text-align: center;
    width: 90px;
    height: 30px;
    color: #2b77fa;
    font-size: 14px;
    padding: 0px 2px;
    font-family: Lato;
    white-space: nowrap;
  }
}

.TXT_phrases {
  a {
    margin-right: 5px;
    color: #2142cf;
  }
}

.TXT_gstyle-ads {
  text-align: left;
  padding-bottom: 20px;
  font-size: 14px;
  line-height: 20px;

  .TXT_tad-title {
    display: block;
    font-size: 1.2em;

    a {
      color: $gTextAdColor !important;
      padding: 3px 0;
      text-decoration: none;

      &:hover {
        color: #1a0dab !important;
        text-decoration: underline;
      }
    }
  }

  .TXT_tad-host {
    a {
      display: flex;
      align-items: center;
      color: #454545;
      text-decoration: none;

      &:hover {
        color: #454545 !important;
        text-decoration: none;
      }
    }

    .TXT_ad-label {
      font-weight: bold;
    }
  }

  .TXT_tad-dot {
    padding-right: 3px;
    padding-left: 3px;
    font-size: 9px;
  }

  .TXT_tad-dot-fv {
    align-self: center;
    padding-right: 3px;
    max-width: 20px;
  }

  .TXT_wrapper {
    position: relative;
  }

  .TXT_descriptionWrapper {
    width: calc(100% - 100px);
    padding-bottom: 3px;
    margin-bottom: 3px;

    a {
      color: #454545 !important;
      text-decoration: none;

      &:hover {
        color: #454545 !important;
        text-decoration: none;
      }
    }

    @media (max-width: $mobile) {
      padding-right: 0;
      width: 100%;
    }
  }

  .TXT_action-item {
    border: 1px solid #1a0dab;
    color: #1a0dab;
    font-size: 14px;
    font-weight: bold;
    width: 80px;
    height: 32px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    text-decoration: none;
    float: right;
    margin-left: 10px;
    margin-bottom: 5px;

    &:hover {
      background-color: #f7f7fa;
      text-decoration: none;
    }
  }

  .TXT_phrases {
    a {
      color: #454545 !important;
      padding: 0;
      margin: 0;
      text-decoration: none;
    }
  }

  .TXT_topAdExtension {
    text-decoration: none;
    color: #454545 !important;
  }

  .TXT_imgWrapper {
    position: absolute;
    top: 5px;
    right: 10px;
    text-align: center;
    display: block;

    .TXT_imgInAds {
      max-width: 48px;
      object-fit: contain;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-origin: content-box;
    }
  }

  .TXT_siteLinks {
    a {
      color: #454545 !important;
      text-decoration: underline !important;
    }
  }

  .TXT_callouts {
    color: #454545 !important;
    text-decoration: none;
  }

  .TXT_review {
    color: #454545 !important;
    text-decoration: none;
  }
}

.TXT_text-ads-v4 {
  max-width: 100%;
  padding-top: 2px;

  .TXT_tad-host {
    display: block;
    margin-bottom: 7px;

    a span:last-child {
      word-break: break-all;
    }
  }

  .TXT_tad-title {
    padding: 0;
    margin: 0 0 7px 0;
    font-size: 18px;
  }

  .TXT_tad-dot {
    padding-right: 5px;
    padding-left: 5px;
    font-size: 7px;
  }

  .TXT_tad-dot-fv {
    padding-right: 5px;
  }

  .TXT_descriptionWrapper {
    line-height: 1.3;
    color: #4d5156 !important;
    text-align: justify;
    padding-bottom: 0;
    margin-bottom: 0;

    b {
      font-weight: 500;
    }
  }

  .TXT_titleImageLimiter {
    width: calc(100% - 64px);
  }
}

.ie[data-section="sidetextads"] {

  .TXT_title,
  .TXT_host {
    display: block;
    word-break: break-word;
    width: calc(100% - 50px);
  }

  .TXT_host {
    word-break: break-all;
  }
}