@import 'src/scss/variables';

.FSR_main-wrapper {
	position: relative;
	// min-height: 100vh;
	overflow-x: hidden;
	max-width: $maxWidthScreen;
	@media (max-width: $desktop) {
		padding: 0px 7px;
	}
	.FSR_pageWrapper {
		display: flex;
		.FSR_feedWrapper {
			width: 100%;
			.FSR_similar-products {
				display: flex;
				flex-wrap: wrap;
				width: 100%;
			}
		}
		.FSR_rightWrapper {
			margin: 10px 0px 0px 10px;
			width: 100%;
			max-width: $thirdColumnGrid;
		}
		@media (min-width: $largeDesktop) {
			.FSR_leftWrapper {
				min-width: $firstColumnGrid;
			}
		}
	}
}
