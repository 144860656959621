@import 'src/scss/variables';

$paddingFromSide: 10px;

@mixin primeRight {
	&.LightProductAd_prime {
		div.LightProductAd_logoWrapper {
			top: 165px;
		}
		.LightProductAd_prime-right {
			position: absolute;
			height: 15px;
			right: 10px;
			top: 169px;
		}
	}
}
@mixin loading-spinner-test {
	&.LightProductAd_loading {
		height: 80px;
		top: 50px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}
	transition: 0.4s all ease-in-out;
}

@mixin othersProductAd {
	@include primeRight;
	overflow: hidden;
	flex-basis: auto;
	font-size: 20px;
	text-align: center;
	line-height: 35px;
	text-decoration: none;
	height: 340px;
	min-height: 340px;
	width: 100%;
	background: #ffffff;
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
	border-radius: 5px;

	@media (max-width: $mobile) {
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
	}

	&:hover {
		transition: all 0.2s ease-in-out;
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
		background: #f0f9ff;

		.LightProductAd_product-details {
			.LightProductAd_view-on-store-wrapper {
				transition: background 0.2s ease-in-out;
				background: #2b77fa;
				.LightProductAd_view-on-store {
					transition: color 0.2s ease-in-out;
					color: white;
				}
			}
		}
	}
	.LightProductAd_price {
		position: absolute;
		color: rgb(109, 108, 108);
		font-weight: bold;
		top: 241px;
		left: 0px;
		padding: 0 $paddingFromSide;
		width: 120%;
		text-align: left;
		font-size: 15px;
		line-height: 20px;
		& span {
			margin-right: 5px;
		}
	}
	.LightProductAd_price-strikethrough {
		text-decoration: line-through;
		font-weight: normal;
		font-size: 12px;
		text-decoration-color: red;
		text-decoration-thickness: 2px;
	}

	.LightProductAd_discount-ribbon {
		position: absolute;
		width: 68px;
		height: 30px;
		z-index: 1;
		top: 13px;
		right: 13px;
		background: #ff3773;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		&:before {
			content: '';
			position: absolute;
			top: 0px;
			left: -15px;
			width: 0;
			height: 0;
			border-bottom: 15px solid transparent;
			border-right: 15px solid #ff3773;
			z-index: 2;
		}
		&:after {
			content: '';
			position: absolute;
			left: -15px;
			width: 0;
			top: 15px;
			height: 0;
			border-top: 15px solid transparent;
			border-right: 15px solid #ff3773;
			z-index: 2;
		}
		.LightProductAd_discount-ribbon-content {
			font-family: Roboto;
			font-weight: bold;
			color: $white;
			font-style: italic;
			font-size: 15px;
			height: 30px;
			line-height: 15px;
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			top: -1px;
			left: -2px;
		}
	}

	.LightProductAd_product-details {
		position: relative;
		height: 100%;
		width: 100%;

		.LightProductAd_brand-name {
			position: absolute;
			top: 266px;
			left: 0px;
			padding: 0 $paddingFromSide;
			width: 100%;
			text-align: left;
			font-size: 14.5px;
			line-height: 17px;
			color: #a7b0b4;
			@media (max-width: $mobile) {
				max-height: 34px;
				overflow: hidden;
			}
		}

		.LightProductAd_logoWrapper {
			position: absolute;
			top: 143px;
			left: 10px;
			img {
				max-height: 20px;
				max-width: 100%;
			}
		}

		.LightProductAd_thumbnail {
			flex: 1;
			overflow: hidden;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			height: 150px;
			position: absolute;
			width: 80%;
			left: $paddingFromSide;
			top: 18px;
			margin: 0%;
			object-fit: contain;
			@include loading-spinner-test();
		}

		.LightProductAd_product-title {
			font-size: 18px;
			text-align: left;
			font-weight: bold;
			padding: 0px $paddingFromSide;
			display: block;
			color: #24282a;
			max-height: 40px;
			line-height: 20px;
			overflow: hidden;
			margin: auto;
			position: absolute;
			top: 198px;
			width: 100%;
			display: block;
		}

		.LightProductAd_product-price {
			display: none;
		}
		.LightProductAd_view-on-store-wrapper {
			padding: 5px;
			width: 100%;
			display: flex;
			position: absolute;
			bottom: 0px;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;

			.LightProductAd_view-on-store {
				font-size: 18px;
				color: #2b77fa;
			}
			.LightProductAd_icon {
				display: none;
			}
		}
	}
}
