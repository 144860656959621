* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Roboto', sans-serif;
}

img {
	max-width: 100%;
}

.product-debug, .textad-debug {
	z-index: 9;
	background: white;
	position: absolute;
	right: 0px;
	top: 0px;
	color: black;
	font-size: 14px;
}

// Cookiebot fix css
#CybotCookiebotDialog {
	* {
		font-size: 13px !important;
	}

	.CybotCookiebotDialogBodyContentLabelPersonalInformation {
		position: relative !important;
		top: 3px !important;
	}

	&.CybotEdge {
		top: 100% !important;
		transform: translate(-50%, -100%) !important;
		box-shadow: 0 30px 70px rgba(0, 0, 0, 0.3) !important;
		height: auto !important;
		max-height: 70vh !important;
		max-width: 100vw !important;
		min-width: 100vw !important;
		padding: 10px 15px 10px 15px !important;
		width: 100vw !important;

		.CybotCookiebotDialogContentWrapper {
			max-width: 1400px !important;
			margin: 0 auto !important;
		}

		.CybotCookiebotDialogBodyContentLabelPersonalInformation {
			font-size: 13px !important;
		}

		#CybotCookiebotDialogBodyButtonAccept {
			padding: 0.7em !important;
		}

		.CybotCookiebotDialogBodyButton {
			padding: 0.5em !important;
		}

		#CybotCookiebotDialogHeader {
			display: none !important;
		}

		.CybotCookiebotDialogBodyLevelButtonWrapper {
			padding: 0.4em !important;
		}

		.CybotCookiebotDialogBodyBottomWrapper {
			align-items: center !important;
		}

		#CybotCookiebotDialogBodyLevelButtonsSelectPane {
			padding: 0.3em 0 !important;

			.CybotCookiebotDialogBodyLevelButtonSliderWrapper {
				height: 25px !important;
				width: 52px !important;

				.CybotCookiebotDialogBodyLevelButtonSlider {
					&::before {
						bottom: 2px !important;
						height: 21px !important;
						left: 2px !important;
						width: 20px !important;
					}
				}
			}
		}
	}
}

@media (min-width: 1281px) {
	#CybotCookiebotDialogBodyContentText {
		font-size: 14px !important;
	}

	.CybotEdge {
		#CybotCookiebotDialogFooter {
			padding: 40px 0px 0px 0px !important;
		}
	}
}

@media (min-width: 587px) and (max-width: 1280px) {
	#CybotCookiebotDialog {
		.CybotCookiebotDialogContentWrapper {
			max-width: 1100px !important;
			margin: 0 auto !important;
		}

		#CybotCookiebotDialogBodyContentText {
			font-size: 13px !important;
			line-height: 18px !important;
		}

		#CybotCookiebotDialogBodyContent {
			padding: 0px !important;
		}

		#CybotCookiebotDialogBodyEdgeMoreDetails {
			margin: 5px 10px 5px 10px !important;
		}

		#CybotCookiebotDialogFooter {
			padding: 3px !important;
		}
	}
}

@media (max-width: 600px) {
	#CybotCookiebotDialog {
		padding: 5px 15px 5px 15px !important;

		.CybotCookiebotDialogContentWrapper {
			max-width: 1100px !important;
			margin: 0 auto !important;
		}

		#CybotCookiebotDialogBodyContentText {
			font-size: 13px !important;
			line-height: 18px !important;
			overflow-y: scroll !important;
			height: 50px !important;
		}

		#CybotCookiebotDialogBodyEdgeMoreDetails {
			margin: 5px 10px 5px 10px !important;
		}

		#CybotCookiebotDialogBodyContent {
			padding: 0px !important;
			line-height: 1.3em !important;
		}

		.CybotCookiebotScrollContainer {
			min-height: 95px !important;
		}

		.CybotCookiebotDialogBodyContentControlsWrapper {
			padding: 5px 0px 10px 0px !important;

			.CybotCookiebotDialogBodyContentLabelPersonalInformation {
				font-size: 13px !important;
			}
		}

		#CybotCookiebotDialogBodyEdgeMoreDetailsLink {
			font-size: 14px !important;
		}

		.CybotCookiebotDialogBodyLevelButtonWrapper {
            width: 50% !important;
        }

	}

	.hap-eu {
		#CybotCookiebotDialogTabContent {
			height: 155px !important;
		}
		#CybotCookiebotDialogFooter {
			padding: 6px 0px 0px 0px !important;
		}
	}
	.hap-en {
		#CybotCookiebotDialogFooter {
			padding: 3px !important;
		}
	}
}

.hap-eu {
	.CybotCookiebotDialogBodyContentControlsWrapper {
		display: none !important;
	}
}