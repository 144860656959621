@import 'src/scss/variables';

.FP_wrapper {
	min-height: 80vh;
	background-color: $site-background;
	position: relative;
}

.FP_bannerWrapper {
	background-color: #202020;
	width: 100%;
	height: 100%;
	.FP_banner {
		background-image: url('/shared/images/cm-2020-mob.png');
		height: 100%;
		width: 100%;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		margin: 0 auto;
	}
	@media (min-width: $tablet) {
		background-color: #000000;
		.FP_banner {
			background-image: url('/shared/images/cm-2020-dsk.png');
		}
	}
}
