// DEVICES

$minimum-support: 345px;
$small-mobile: 374px;
$mobile: 544px;
$tablet: 769px;
$laptop: 1024px;
$desktop: 1140px;
$largeDesktop: 1240px;
$veryLargeDesktop: 1500px;

$maxWidthScreen: 1700px;
$searchBarWidth: 1266px;
$firstColumnGrid: 200px;
$thirdColumnGrid: 200px;

// LP
$maxWidthLPInnerScreen: 1140px;

// COLORS
$blue: #166bf6;
$white: #ffffff;
$black: #000000;
$green: #7abf12;
$pink: #dd688d;

$site-background: $white;
$site-header: $white;