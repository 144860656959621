@import 'src/scss/variables';

@media (min-width: $tablet) {
	.SB_search-bar-wrapper {
		display: flex;

		.SB_search-bar {
			display: flex;
		}
	}
}

@mixin homePageSearchBar {
	display: flex;
	justify-content: center;
	margin: 0 auto;
	.SB_search-bar {
		width: 100%;
		padding: 0px;
		max-width: 700px;
		@media (min-width: $tablet) and (max-width: $laptop) {
			max-width: 600px;
		}

		.SB_search-bar-inner {
			padding: 0px;
			margin: 0 auto;
			background: transparent;
		}
	}
	.homepageSearchBar {
		border: 2px solid $blue;
		width: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: center;
		border-radius: 3px 0px 0px 3px;

		.SB_search-input {
			display: initial !important;
			padding-left: 15px !important;
			background: $white !important;
			height: 45px !important;
			width: 100% !important;
			border: none !important;
			@media (max-width: $mobile) {
				font-size: 16px !important;
			}
		}

		.SB_search-icon {
			cursor: pointer;
			background: $blue !important;
			color: $white;
			border: none;
			width: 150px !important;
			min-width: 105px;
			@media only screen and (min-width: 768px) {
				min-width: 125px;
			}
			font-family: Roboto !important;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			text-transform: uppercase;
		}
		.SB_clear-input {
			margin-right: 5px;
		}
	}
}

.SB_search-bar-wrapper {
	width: 100%;
	max-width: $searchBarWidth;
	@media (max-width: $tablet) {
		width: 100%;
	}

	&.homePageSearchWrapper {
		@include homePageSearchBar;
	}

	.SB_search-bar {
		display: flex;
		width: 98%;
		padding: 5px 0px 5px 0px;
		margin: 0 auto;
		@media (min-width: $laptop) {
			width: 100%;
			height: 55px;
		}

		.SB_search-bar-inner {
			border: 1px solid #dfe1e5;
			background-color: $white !important;
			border-radius: 10px !important;
			border-radius: 4px;
			flex: 1;
			display: flex;
			align-items: center;
			padding: 5px;
			position: relative;

			.SB_search-form {
				display: flex;
				margin-left: auto;
				width: 100% !important;
				position: relative;

				&.SB_collapsed {
					width: 10%;
				}

				.SB_search-form-inner {
					display: flex;
					margin-left: auto;
					width: 100% !important;
					.SB_search-input {
						width: 100%;
						z-index: 6;
						border: none;
						padding: 10px;
						border-radius: 3px;
						font-size: 15px;
						background: transparent;
						color: $black;
						outline: 0;

						&.SB_loading {
							background-image: url('/fresh/images/blue-spinner.svg');
							background-position: center left 10px;
							background-size: 20px auto;
							background-repeat: no-repeat;
							padding-left: 10px !important;
						}

						&.SB_proper-case {
							text-transform: capitalize;
						}
					}

					.SB_search-icon {
						border: none;
						background: transparent;
						margin-left: auto;
						cursor: pointer;
						width: 34px;
						padding: 5px;
						outline: 0;
						img {
							min-width: 22px;
						}
						.SB_search-input {
							width: 100%;
							z-index: 6;
							border: none;
							padding: 10px;
							border-radius: 3px;
							font-size: 16px;
						}
					}
					.SB_clear-input {
						display: flex;
						justify-self: center;
						align-items: center;
						min-width: 24px;
						margin-right: 8px;
						min-height: 24px;
						cursor: pointer;
						svg {
							path {
								fill: #71717A;
							}
							width: 100%;
							margin-bottom: 2px;
							max-width: 50px;
						}
					}
					.SB_bot {
						background-image: url('/fresh/images/bot-icon.svg');
						background-position: center left 10px;
						background-size: 25px auto;
						background-repeat: no-repeat;
						width: 50px;
						outline: none;
						background-color: transparent;
						border: none;
						cursor: pointer;
					}
				}
			}

			.SB_autocomplete-list {
				position: absolute;
				top: 43px;
				left: 0;
				list-style: none;
				background: #fff;
				z-index: 99;
				width: 100%;
				overflow: hidden;
				margin-top: 0px;
				padding: 8px 0;
				border: 1px solid rgba(0, 0, 0, 0.2);
				border-radius: 3px;
				box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

				li {
					padding: 5px 10px;
					font-size: 16px;
					line-height: 24px;
					cursor: pointer;

					&:hover {
						color: #333;
						background-color: #efefef;
					}

					&.ac-active {
						color: #333;
						background-color: #efefef;					}
				}

				&:empty {
					border: none;
					padding: 0;
				}
			}
		}
	}
}
