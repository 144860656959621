@import 'src/scss/variables';
@import 'src/scss/mixins/background';

html, body {margin: 0; height: 100%; overflow-y: visible}

.HDR_freshHeader {
	z-index: 99;
	position: relative;
	
	.HDR_header-content {
		position: relative;
		background-color: $site-header;
		@media (min-width: $laptop) {
			padding-top: 10px;
			margin-left: 70px;
			display: flex;
			align-items: center;
			max-width: 1480px;
			width: 90%;
			.HDR_mobileWrapper {
				margin: 0 !important;
				width: 200px !important;
			}
		}
		.HDR_mobileWrapper {
			background-color: $site-header;
			width: 98%;
			height: 44px;
			justify-content: space-between;
			display: flex;
			align-items: center;
			margin: 0 auto;
			.HDR_actions {
				height: 21px;
				margin-left: auto;
				display: flex;
				.HDR_fixed-search-icon {
					display: none;
					border: none;
					background: transparent;
					cursor: pointer;
					width: 24px;
					margin-right: 10px;
					position: relative;
					top: -2px;
				}
			}
		}

		.HDR_search-bar-logo {
			display: flex;
			justify-content: center;
			height: 100%;
			padding: 4px;

			@media (min-width: $laptop) {
				margin-right: 10px;
				display: block;
				float: left;
			}
		}

		.HDR_searchBarWrapper {
			width: 100%;
			@media (min-width: $laptop) {
				width: calc(100% - 400px);
				max-width: 600px;
				margin-left: 20px;
			}
		}

		.HDR_dropdownWrapper {
			position: absolute;
			right: 0px;
			top: 13px;
		}
	}
}

.HDR_fixed-nav {
	.HDR_mobileWrapper {
		position: fixed;
		box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
		width: 100% !important;
		top: 0;
		z-index: 9;
		padding: 0px 5px;
		.HDR_fixed-search-icon {
			margin-left: 10px;
			display: block !important;
		}
	}

	&.scrolledUp {
		padding-top: 60px !important;

		.HDR_header-content {
			position: fixed;
			width: 100%;
			top:40px;
			animation-name: slideIn;
			animation-duration: 300ms;

			.HDR_mobileWrapper {
				box-shadow: none;

				.HDR_actions {
					.HDR_fixed-search-icon {
						display: none !important;
					}
				}
			}

			.HDR_searchBarWrapper {
				box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
			}
		}
	}
}


@keyframes slideIn {
	from {top: 0;}
	to {top: 40px;}
  }