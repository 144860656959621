@import 'src/scss/variables';

@mixin loading-spinner-test {
	&.LightProductAd_loading {
		height: 80px;
		top: 50px;
		left: 0;
		right: 0;
		margin: 0 auto;
	}
	transition: 0.4s all ease-in-out;
}

@mixin yahooProductAd {
	overflow: hidden;
	flex-basis: auto;
	font-size: 15px;
	text-align: center;
	line-height: 35px;
	text-decoration: none;
	height: 340px;
	min-height: 340px;
	width: 100%;
	background: #ffffff;
	box-shadow: 5px 5px 10px rgba(214, 214, 215, 0.25), -5px -5px 10px rgba(255, 255, 255, 0.25);
	border-radius: 4px 4px 6px 6px;

	@media (max-width: $mobile) {
		box-shadow: 5px 5px 10px rgba(214, 214, 215, 0.25), -5px -5px 10px rgba(255, 255, 255, 0.25);
	}

	&:hover {
		box-shadow: 5px 5px 10px rgba(38, 94, 239, 0.25), -5px -5px 10px rgba(255, 255, 255, 0.25);
		transition: box-shadow 0.2s ease-in-out;

		.LightProductAd_product-details {
			.LightProductAd_view-on-store-wrapper {
				transition: background 0.2s ease-in-out;
				background: #2b77fa;
				.LightProductAd_view-on-store {
					transition: color 0.2s ease-in-out;
					color: white;
				}
				.LightProductAd_icon {
					filter: invert(99%) sepia(100%) saturate(2%) hue-rotate(265deg) brightness(108%) contrast(101%);
				}
			}
		}
	}

	.LightProductAd_product-details {
		position: relative;
		height: 100%;
		width: 100%;

		.LightProductAd_ad-label {
			font-size: 14px;
			color: #cccccc;
			font-family: Lato;
			font-weight: 300;
			position: absolute;
			top: 4px;
			right: 5px;
			text-align: right;
			line-height: 14px;
			width: 100%;
		}

		.LightProductAd_brand-name {
			color: #4ba786;
			line-height: 16px;
			font-size: 14px;
			position: absolute;
			text-align: left;
			width: 100%;
			top: 258px;
			padding: 0 10px;
		}

		.LightProductAd_logoWrapper {
			position: absolute;
			top: 165px;
			left: 15px;
			display: flex;
			justify-content: flex-end;
			max-width: 50%;

			img {
				max-height: 20px;
				max-width: 100%;
			}
		}

		.LightProductAd_thumbnail {
			flex: 1;
			overflow: hidden;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			height: 136px;
			position: absolute;
			width: 80%;
			left: 10%;
			top: 28px;
			margin: 0%;
			object-fit: contain;
			@include loading-spinner-test();
		}

		.LightProductAd_product-title {
			font-size: 15.5px;
			text-align: left;
			padding: 0px 10px;
			display: block;
			max-height: 40px;
			color: #265eef;
			line-height: 20px;
			overflow: hidden;
			margin: auto;
			position: absolute;
			top: 200px;
			width: 100%;
			display: block;
		}

		.LightProductAd_product-price {
			left: 0px;
			top: 5px;
			text-align: left;
			padding-left: 5px;
			color: #cccccc;
			font-weight: normal;
			font-size: 14px;
			line-height: 14px;
			position: absolute;
			letter-spacing: 1px;
			width: 100%;
		}
		.LightProductAd_view-on-store-wrapper {
			padding: 5px;
			width: 100%;
			display: flex;
			position: absolute;
			bottom: 0px;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			background: #d2e9ff;
			border-radius: 0px 0px 6px 6px;

			.LightProductAd_view-on-store {
				font-size: 18px;
				color: #2b77fa;
				display: flex;
				align-items: center;
			}
			.LightProductAd_icon {
				filter: invert(43%) sepia(80%) saturate(4080%) hue-rotate(207deg) brightness(100%) contrast(97%);
				color: #2b77fa;
				position: relative;
				left: 5px;
				opacity: 0.8;
			}
		}
	}
}
