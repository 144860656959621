@import 'src/scss/variables';

@mixin marginColumn($columns, $gutter) {
	margin-right: calc(($gutter * $columns) / ($columns - 1));
	&:nth-of-type(#{$columns}n) {
		margin-right: 0px;
	}
}

@mixin grid($veryLargeDesktopColumns: 5, $desktopColumns: 4, $laptopColumns: 3, $tabletColumns: 3, $mobileColumns: 2) {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: calc(50% - 20px);
	max-height: 450px;
	margin-bottom: 10px;

	// mobileColumns < 544
	@media (max-width: $mobile) {
		$gutter: 3px;
		width: calc((100% / #{$mobileColumns}) - #{$gutter});
		@include marginColumn($mobileColumns, $gutter);
	}

	// tabletColumns 544 - 769
	@media (min-width: $mobile) and (max-width: $laptop) {
		$gutter: 6px;
		width: calc((100% / #{$tabletColumns}) - #{$gutter});
		@include marginColumn($tabletColumns, $gutter);
	}

	// laptopColumns 1024 - 1140
	@media (min-width: $laptop) and (max-width: $desktop) {
		$gutter: 8px;
		width: calc((100% / #{$laptopColumns}) - #{$gutter});
		@include marginColumn($laptopColumns, $gutter);
	}

	// desktopColumns 1140 - 1500
	@media (min-width: $desktop + 1) and (max-width: $veryLargeDesktop) {
		$gutter: 10px;
		width: calc((100% / #{$desktopColumns}) - #{$gutter});
		@include marginColumn($desktopColumns, $gutter);
	}

	// veryLargeDesktopColumns > 1500
	@media (min-width: $veryLargeDesktop + 1) {
		$gutter: 12px;
		margin-bottom: 20px;
		width: calc((100% / #{$veryLargeDesktopColumns}) - #{$gutter});
		@include marginColumn($veryLargeDesktopColumns, $gutter);
	}
}
