@import 'src/scss/variables';

.FWTA_full-width-text-promos {
	background: white !important;
	border-radius: 5px;
	position: relative;
	text-align: right;
	width: 100%;
	position: relative;
	text-align: right;
	padding: 3px 10px 3px 10px;
	padding-top: 3px;
	border-top: 1px solid #f5f5f5;

	@media (max-width: $mobile) {
		padding: 0px !important;
	}

	.FWTA_textpromo-label {
		font-size: 11px;
		line-height: 1.5;
		color: #9c9c9c;
		border-radius: 4px;
		padding: 3px 4px;
		display: inline-block;
	}
}