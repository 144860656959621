@import 'src/scss/variables';
@import 'src/scss/mixins/grid';

@keyframes scaleop {
	0% {
		transform: scale(0.5);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

.LoadingOverlay_loading-welcome {
	text-align: center;
	background: white;
	height: 100%;
	width: 100%;
	min-height: 1000px;
	top: 0px;
	padding-top: 30px;

	.LoadingOverlay_topLine {
		width: 90%;
		margin-top: 30px !important;
		margin: 0 auto;
		@media (min-width: $mobile) {
			.LoadingOverlay_logosWrapper {
				.LoadingOverlay_logos {
					img {
						margin: 20px !important;
					}
				}
			}
		}
		.LoadingOverlay_title {
			max-width: 400px;
			font-weight: 100;
			margin: 0 auto;
			text-align: center;
			line-height: 28px;
			font-size: 20px;
		}
		.LoadingOverlay_logosWrapper {
			text-align: center;
			max-width: 850px;
			margin: 0 auto;
			.LoadingOverlay_logos {
				margin-top: 10px;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;
				img {
					max-width: 110px;
					max-height: 50px;
					opacity: 0;
					animation: scaleop 0.5s forwards;
					vertical-align: middle;

					&.normal {
						&:nth-child(2n) {
							animation-delay: 0.1s;
						}
						&:nth-child(3n) {
							animation-delay: 0.2s;
						}
						&:nth-child(4n) {
							animation-delay: 0.3s;
						}
					}
				}
			}
		}
	}

	.LoadingOverlay_similar-products {
		margin: 10px auto;
		display: flex;
		flex-wrap: wrap;
		width: 90%;
		max-width: 1400px;
		.LoadingOverlay_product-promo {
			border: 1px solid #eaeaea;
			border-radius: 8px;
			overflow: hidden;
			text-decoration: none;
			@include grid();
			max-height: 400px;

			.LoadingOverlay_product-details {
				background-color: #ffffff;
				background-position: bottom;
				background-size: 7px 1px;
				background-repeat: repeat-x;
				height: 85%;
				width: 100%;
				.LoadingOverlay_product-title {
					font-size: 14px;
					height: 38px;
					line-height: 1.36;
					text-align: center;
					color: #494949;
					margin-top: 10px;
					margin-bottom: 10px;
					padding: 0px 10px;
					display: block;
					text-overflow: ellipsis;
					word-wrap: break-word;
					overflow: hidden;
					max-height: 4.3em;
				}
			}
			.LoadingOverlay_view-on-store-wrapper {
				padding: 10px;
				width: 100%;
				display: flex;
				align-items: flex-end;
			}
			.LoadingOverlay_thumbnail {
				border-bottom: 1px solid #eaeaea;
				height: 150px;
				flex: none;
				width: 100%;
				background-size: 800px 104px !important;
				background-repeat: inherit;
				background-position: -468px 0;
			}

			.LoadingOverlay_dummy-paragraph {
				height: 10px;
				width: 50%;
				margin: 4px 0;
				background: #eaeaea;
			}
		}
	}
}
