@import 'src/scss/variables';
@import 'src/scss/mixins/grid';

.FPG_grid {
	@include grid();
	&.FPG_full {
		width: 100%;
	}
	&.FPG_fullWidthTextAd {
		box-shadow: 0 7px 9px 0 rgba(209, 213, 223, 0.5);
		min-height: 120px;
		display: flex;
		justify-content: center;
		padding: 5px;
		margin: 0px auto 10px auto !important;
		border-radius: 5px;
		background-color: white;
	}
}

.ebayPolicy {
	color: black;
	border: 1px solid #e5e5e5;
	// color: white;
	font-size: 13px;
	// background-color: #3f4040;
	width: 100%;
	padding: 20px;
	margin: 20px 0;
}
